var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"container-switch"},[_c('p',[_vm._v("¿Requiere subir un logo?")]),_c('switches',{attrs:{"color":"blue"},model:{value:(_vm.enabled),callback:function ($$v) {_vm.enabled=$$v},expression:"enabled"}})],1),(_vm.enabled == true)?_c('UploadLogo',{on:{"getURL":_vm.changeFormState}}):_vm._e(),_c('div',{staticClass:"contanier"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-12"},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Nombre de la sección"},model:{value:(_vm.seccion.name),callback:function ($$v) {_vm.$set(_vm.seccion, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"seccion.name"}})],1),_c('div',{staticClass:"col-12"},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Ingresa una breve descripción"},model:{value:(_vm.seccion.description),callback:function ($$v) {_vm.$set(_vm.seccion, "description", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"seccion.description"}})],1),_c('div',{staticClass:"col-6 col-md-2"},[_c('b-form-select',{attrs:{"options":_vm.options,"placeholder":"Tipo de Sección"},model:{value:(_vm.seccion.tipo),callback:function ($$v) {_vm.$set(_vm.seccion, "tipo", $$v)},expression:"seccion.tipo"}})],1),(_vm.seccion.tipo == 1)?_c('div',{staticClass:"col-12 col-md-8"},[_c('b-form-input',{attrs:{"type":"url","placeholder":"Ingresa la URL"},model:{value:(_vm.seccion.url),callback:function ($$v) {_vm.$set(_vm.seccion, "url", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"seccion.url"}})],1):_vm._e(),_c('div',{staticClass:"col-6 col-md-2"},[_c('b-form-input',{attrs:{"type":"number","placeholder":"Orden"},model:{value:(_vm.seccion.orden),callback:function ($$v) {_vm.$set(_vm.seccion, "orden", _vm._n($$v))},expression:"seccion.orden"}})],1)]),(
          _vm.seccion.name &&
            _vm.seccion.description &&
            _vm.seccion.tipo == 1 &&
            _vm.seccion.url &&
            _vm.seccion.orden &&
            _vm.urlImagen
        )?_c('b-button',{staticClass:"accept",attrs:{"type":"submit"}},[_vm._v(" Guardar Link ")]):_vm._e(),(
          _vm.seccion.name &&
            _vm.seccion.description &&
            _vm.seccion.tipo == 2 &&
            _vm.seccion.orden &&
            _vm.urlImagen
        )?_c('b-button',{staticClass:"accept",attrs:{"type":"submit"}},[_vm._v(" Guardar Sección ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }