<template>
  <div class="container">
    <div class="container-switch">
      <p>¿Requiere subir un logo?</p>
      <switches v-model="enabled" color="blue"></switches>
    </div>
    <UploadLogo v-if="enabled == true" v-on:getURL="changeFormState" />

    <div class="contanier">
      <b-form @submit.prevent="onSubmit">
        <div class="form-row">
          <div class="col-12">
            <b-form-input
              v-model.trim="seccion.name"
              type="text"
              placeholder="Nombre de la sección"
            ></b-form-input>
          </div>
          <div class="col-12">
            <b-form-input
              v-model.trim="seccion.description"
              type="text"
              placeholder="Ingresa una breve descripción"
            ></b-form-input>
          </div>
          <div class="col-6 col-md-2">
            <b-form-select
              v-model="seccion.tipo"
              :options="options"
              placeholder="Tipo de Sección"
            ></b-form-select>
          </div>
          <div class="col-12 col-md-8" v-if="seccion.tipo == 1">
            <b-form-input
              v-model.trim="seccion.url"
              type="url"
              placeholder="Ingresa la URL"
            ></b-form-input>
          </div>
          <div class="col-6 col-md-2">
            <b-form-input
              v-model.number="seccion.orden"
              type="number"
              placeholder="Orden"
            ></b-form-input>
          </div>
        </div>
        <b-button
          class="accept"
          v-if="
            seccion.name &&
              seccion.description &&
              seccion.tipo == 1 &&
              seccion.url &&
              seccion.orden &&
              urlImagen
          "
          type="submit"
        >
          Guardar Link
        </b-button>
        <b-button
          class="accept"
          v-if="
            seccion.name &&
              seccion.description &&
              seccion.tipo == 2 &&
              seccion.orden &&
              urlImagen
          "
          type="submit"
        >
          Guardar Sección
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import firebase from "../firebase";
import UploadLogo from "../components/UploadLogo";
import Switches from "vue-switches";

export default {
  name: "NewSection",
  components: {
    Switches,
    UploadLogo,
  },
  data() {
    return {
      ref: firebase.firestore().collection("secciones"),
      seccion: {},
      selected: "",
      options: [
        { value: null, text: "Tipo de Sección" },
        { value: 1, text: "Link" },
        { value: 2, text: "Sección" },
      ],
      imagenes: [],
      imagen: null,
      urlimagen: "",
      enabled: false,
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.seccion.logo = this.urlImagen;
      this.seccion.edit = false;
      if (this.seccion.tipo == 2) {
        this.seccion.url = "";
      }

      this.ref
        .add(this.seccion)
        .then((docRef) => {
          (this.seccion.name = ""),
            (this.seccion.description = ""),
            (this.seccion.tipo = null),
            (this.seccion.logo = ""),
            (this.seccion.url = ""),
            (this.seccion.orden = null),
            (this.enabled = false),
            (this.edit = false);
        })
        .catch((error) => {
          alert("Error adding document: ", error);
        });
    },
    changeFormState: function(className) {
      this.urlImagen = className;
    },
  },
};
</script>

<style></style>
