<template>
  <div>
    <b-modal
      ref="modal-avisos"
      size="lg"
      hide-footer
      hide-header
      title="Avisos"
    >
      <div class="d-block">
        <img
          class="logocongresomodal"
          src="../assets/logos/XVI-logo-anio2-gris-2020.svg"
          alt=""
        />
        <h5 class="titulo-modal">Avisos</h5>
        <div class="container container-avisos">
          <div
            class="aviso shadow-sm"
            v-for="a in avisos"
            :key="a.key"
            v-html="a.aviso"
          ></div>
        </div>
      </div>
    </b-modal>
    <div class="container-fluid container-welcome">
      <div
        class="container welcome d-flex align-items-center justify-content-center"
      >
        <div class="row">
          <div
            class="col-sm-12 col-lg-4 d-flex align-items-center justify-content-center"
          >
            <h1 class="title-welcome">
              <span>Bienvenidos al Micrositio de las </span>
              <br />Audiencias Públicas de Opinión sobre los Derechos Sexuales y
              Reproductivos de las Mujeres de Quintana Roo.
            </h1>
          </div>
          <div class="col-sm-12 col-lg-8">
            <div class="personal-card shadow-sm">
              <div class="fondo"></div>
              <div class="data-card">
                <img src="../assets/vector-dsr-mujeresqroo.svg" alt="" />
                <p>
                  Con base en los principios del Parlamento Abierto y con el
                  compromiso de contribuir a la construcción de un marco
                  jurídico que garantice a las y los quintanarroenses el goce de
                  los derechos y libertades consagradas en la Constitución
                  Política de los Estados Unidos Mexicanos, la Decimosexta
                  Legislatura del Congreso del Estado, propone el desarrollo de
                  los
                  <strong
                    >Foros de Opinión “Derechos Sexuales y Reproductivos de las
                    Mujeres en Quintana Roo”</strong
                  >.
                </p>
                <p>
                  El objetivo de los foros, es crear espacios para conocer y
                  difundir entre las y los ciudadanos, la opinión de
                  especialistas, organizaciones civiles e instituciones respecto
                  a las <strong>dos iniciativas presentadas</strong> sobre los
                  <strong>
                    derechos sexuales y reproductivos de las mujeres
                  </strong>
                  en el Estado de Quintana Roo. Cabe destacar que es importante
                  dar a conocer los diversos puntos de vista que existen
                  alrededor del tema, pero la información u opiniones vertidas
                  en cada uno de los encuentros,
                  <strong>
                    no es con carácter vinculatorio para la elaboración,
                    análisis, discusión y aprobación del dictamen </strong
                  >.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container container-link-seccions">
      <div class="row">
        <div class="col-6 col-md-4" v-for="i in items" :key="i.key">
          <div class="box-link-seccion text-center">
            <a v-if="i.tipo == 1" :href="i.url" target="_blank">
              <img
                class="logo-seccion"
                :src="i.logo"
                :alt="'Logo de la sección' + i.name"
              />
              <h5>{{ i.name }}</h5>
            </a>
            <router-link v-if="i.tipo == 2" :to="'/seccion/' + i.key">
              <img
                class="logo-seccion"
                :src="i.logo"
                :alt="'Logo de la sección' + i.name"
              />
              <h5>{{ i.name }}</h5>
            </router-link>
            <p>{{ i.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../firebase";
//import router from "../router";

export default {
  name: "Home",
  data() {
    return {
      show: false,
      ref: firebase.firestore().collection("secciones"),
      refAvisos: firebase.firestore().collection("avisos"),
      items: {},
      avisos: {},
      modal: false,
    };
  },
  created() {
    this.ref.orderBy("orden").onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          name: doc.data().name,
          description: doc.data().description,
          tipo: doc.data().tipo,
          logo: doc.data().logo,
          url: doc.data().url,
        });
      });
    });

    this.refAvisos.where("activo", "==", true).onSnapshot((querySnapshot) => {
      this.avisos = [];
      querySnapshot.forEach((doc) => {
        this.avisos.push({
          key: doc.id,
          aviso: doc.data().aviso,
        });
      });

      if (this.avisos.length != 0) {
        this.showModal();
      }
    });
  },
  methods: {
    showModal() {
      this.$refs["modal-avisos"].show();
    },
  },
};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";

.container-welcome {
  background-color: $bg-04;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.welcome {
  height: auto;
  @include media-breakpoint-up(md) {
    //height: 500px;
  }

  .title-welcome {
    font-family: $font-01;
    font-size: 1.5rem;
    color: $color-07;
    width: auto;
    text-align: center;
    margin: 0 auto;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
    @include media-breakpoint-up(md) {
      width: 400px;
      font-size: 2rem;
      padding-left: 0;
      padding-right: 0;
      //padding-top: 3rem;
    }
    @include media-breakpoint-up(lg) {
      margin-top: -2rem;
      font-size: 2.25rem;
      text-align: left;
    }
    span {
      font-size: 1.2rem;
      font-weight: 200;
      color: $color-06;
      @include media-breakpoint-up(lg) {
        font-size: 1rem;
      }
      @include media-breakpoint-up(xl) {
        font-size: 1.75rem;
      }
    }
  }

  .personal-card {
    border: 1px solid $bg-02;
    text-align: center;
    width: auto;
    margin: 0 auto;
    //smargin-left: 1rem;
    //margin-right: 1rem;
    @include media-breakpoint-up(lg) {
      //width: 350px;
      //margin: 0 0 0 auto;
    }
    .fondo {
      background: $color-05;
      height: 100px;
    }
    .data-card {
      padding: 1rem;
      background: $bg-03;

      @include media-breakpoint-up(md) {
        padding: 2rem;
      }

      img {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        //border: 4px solid white;
        margin-top: -6.1rem;
        margin-bottom: 1rem;
      }
      h4,
      h5,
      p {
        font-family: $font-02;
        font-weight: 400;
      }
      h4 {
        text-transform: uppercase;
        font-size: 1rem;
        @include media-breakpoint-up(md) {
          font-size: 1.1rem;
        }
      }
      h5 {
        color: $color-03;
        font-size: 0.8rem;
        @include media-breakpoint-up(md) {
          font-size: 0.9rem;
        }
      }
      p {
        font-weight: 300;
        color: $color-02;
        font-size: 0.8rem;
        text-align: justify;
        @include media-breakpoint-up(lg) {
          font-size: 0.85rem;
        }
      }
      a {
        color: $color-03;
      }
    }
  }
}

.container-mision-vision {
  background: $bg-03;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-top: 1px solid $bg-02;
  border-bottom: 1px solid $bg-02;
  @include media-breakpoint-up(md) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .mision-vision {
    list-style: none;
    padding-left: 0;

    li {
      padding: 1.5rem 0.5rem;
      border-top: 1px solid $bg-02;
      &:first-child {
        border-top: none;
      }
      @include media-breakpoint-up(md) {
        padding-left: 3rem;
        padding-right: 3rem;
      }
      @include media-breakpoint-up(lg) {
        width: 50%;
        display: inline-block;
        border-top: none;
        border-left: 1px solid $bg-02;
        &:first-child {
          border-left: none;
        }
      }

      h3 {
        font-family: $font-01;
        font-weight: 400;
      }
      p {
        font-family: $font-02;
        font-weight: 400;
        color: $color-02;
        text-align: justify;
        font-size: 0.9rem;
      }
    }
  }
}

.container-link-seccions {
  margin-top: 3rem;
  margin-bottom: 3rem;

  .box-link-seccion {
    //max-width: 100px;
    margin: 0 auto;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    @include media-breakpoint-up(md) {
      max-width: 250px;
    }

    a {
      cursor: pointer;
      color: $color-01;
      text-decoration: none;

      &:hover {
        h5 {
          color: $color-03;
        }
      }
      .logo-seccion {
        height: 70px;
        @include media-breakpoint-up(md) {
          height: 100px;
        }
      }
      h5 {
        font-family: $font-01;
        font-size: 1rem;
        font-weight: 300;
        color: $color-06;
        margin-top: 2rem;
        @include media-breakpoint-up(md) {
          font-size: 1.25rem;
        }
      }
    }
    p {
      font-size: 0.6rem;
      color: $color-08;
      @include media-breakpoint-up(md) {
        font-size: 0.75rem;
      }
    }
  }
}
</style>
