<template>
  <b-form @submit.prevent="login" @reset="onReset">
    <h1 class="titulo-seccion">Autentificación de Usuario</h1>
    <b-form-group id="input-group-1" label="Usuario:" label-for="input-1">
      <b-form-input
        id="input-1"
        v-model="usuario"
        type="text"
        required
        placeholder="Ingrese su Usuario."
      >
      </b-form-input>
    </b-form-group>

    <b-form-group id="input-group-2" label="Password:" label-for="input-2">
      <b-form-input
        id="input-2"
        v-model="password"
        type="password"
        required
        autocomplete="on"
        placeholder="Ingrese su password."
      ></b-form-input>
    </b-form-group>

    <b-button class="accept m-2" type="submit">Ingresar</b-button>
    <b-button type="reset m-2" href="/">Cancelar</b-button>

    <b-alert v-if="error" show variant="danger">
      {{ error.mensaje }}
    </b-alert>
  </b-form>
</template>

<script>
import firebase from "../firebase";

export default {
  name: "Login",
  data() {
    return {
      usuario: "",
      password: "",
      error: "",
    };
  },
  methods: {
    login(evt) {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.usuario, this.password)
        .then((user) => {
          //console.log(user);
          this.$router.replace({ name: "Admin" });
        })
        .catch((error) => {
          //console.error(error)
          this.error = error;
          if (error.code === "auth/user-not-found") {
            this.error.mensaje =
              "La cuenta de usuario no se encuentra registrada";
          } else if (error.code === "auth/wrong-password") {
            this.error.mensaje = "El password es inválido";
          } else if (error.code === "auth/invalid-email") {
            this.error.mensaje = "El email es inválido";
          } else {
            this.error.mensaje = error.message;
          }
        });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.usuario = "";
      this.password = "";
    },
  },
};
</script>
