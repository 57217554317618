import firebase from "firebase/app";

import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const settings = { timestampsInSnapshots: true };

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyABPefOSh7jcv2q0yRYpArY7pgRi17Q9UY",
  authDomain: "micrositio-dsr-mujeresqroo.firebaseapp.com",
  databaseURL: "https://micrositio-dsr-mujeresqroo.firebaseio.com",
  projectId: "micrositio-dsr-mujeresqroo",
  storageBucket: "micrositio-dsr-mujeresqroo.appspot.com",
  messagingSenderId: "340025726244",
  appId: "1:340025726244:web:7f65bc3c81b9e5bfa84fb1",
  measurementId: "G-1ENYBEM0MF",
};

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings(settings);

export default firebase;
