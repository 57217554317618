<template>
  <div class="container home">
    <MenuAdmin />
    <b-tabs content-class="mt-3">
      <b-tab title="Secciones" active>
        <div class="container-button-add">
          <b-button v-if="showSection" v-on:click="showSection = !showSection">
            <transition name="fade">
              <span>Cancelar</span>
            </transition>
          </b-button>
          <b-button v-if="!showSection" v-on:click="showSection = !showSection">
            <transition name="fade">
              <span>Nueva Sección</span>
            </transition>
          </b-button>
        </div>
        <div v-if="showSection" class="container-new-document">
          <transition name="fade">
            <NewSection v-if="showSection" />
          </transition>
        </div>
        <div class="container-list-documents">
          <h3>Lista de Secciones del Micrositio</h3>
          <transition name="fade">
            <ListSection />
          </transition>
        </div>
      </b-tab>
      <b-tab title="Avisos">
        <div class="container-button-add">
          <b-button v-if="showAviso" v-on:click="showAviso = !showAviso">
            <transition name="fade">
              <span>Cancelar</span>
            </transition>
          </b-button>
          <b-button v-if="!showAviso" v-on:click="showAviso = !showAviso">
            <transition name="fade">
              <span>Nuevo Aviso</span>
            </transition>
          </b-button>
        </div>
        <div v-if="showAviso" class="container-new-document">
          <transition name="fade">
            <NewAviso v-if="showAviso" />
          </transition>
        </div>
        <div class="container-list-documents">
          <h3>Lista de Avisos</h3>
          <transition name="fade">
            <ListAvisos />
          </transition>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import NewAviso from "../components/NewAviso";
import NewSection from "../components/NewSection";
import ListSection from "../components/ListSection";
import ListAvisos from "../components/ListAvisos";
import MenuAdmin from "../components/MenuAdmin";

export default {
  name: "Admin",
  components: {
    NewSection,
    NewAviso,
    ListSection,
    ListAvisos,
    MenuAdmin,
  },
  data() {
    return {
      showSection: false,
      showAviso: false,
    };
  },
};
</script>
