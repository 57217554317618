<template>
  <div class="container">
    <div class="container-switch">
      <p>¿Requiere subir archivos?</p>
      <switches v-model="enabled" color="blue"></switches>
    </div>
    <UploadFile v-if="enabled == true" v-on:getURL="getURLFile" />

    <div class="container">
      <b-form @submit.prevent="onSubmit">
        <div class="form-row">
          <div class="col-12">
            <b-form-input
              v-model.trim="doc.titulo"
              type="text"
              placeholder="Título del Documento"
            ></b-form-input>
          </div>
          <div class="col-12">
            <b-form-input
              v-if="enabled === false"
              v-model.trim="urlFile"
              type="url"
              placeholder="Ingresa la URL"
            ></b-form-input>
          </div>
        </div>
        <b-button class="accept" v-if="doc.titulo && urlFile" type="submit">
          Guardar Documento
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import firebase from "../firebase";
import Switches from "vue-switches";
import UploadFile from "../components/UploadFile";

export default {
  name: "NewDocument",
  components: {
    Switches,
    UploadFile,
  },
  props: ["docto", "typePub"],
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.docto)
        .collection("documentos"),
      doc: {},
      urlFile: "",
      enabled: false,
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      (this.doc.url = this.urlFile), (this.doc.edit = false);

      this.ref
        .add(this.doc)
        .then((docRef) => {
          (this.doc.titulo = ""),
            (this.urlFile = ""),
            (this.enabled = false),
            (this.edit = false);
        })
        .catch((error) => {
          alert("Error al agregar el documento: ", error);
        });
    },
    getURLFile: function(url) {
      this.urlFile = url;
    },
  },
};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";
.container-switch {
  text-align: center;
  p {
    margin-top: 0.5rem;
  }
}
</style>
