<template>
  <div class="container-fluid footer d-flex align-items-center">
    <div class="container">
      <p>
        Colonia Barrio Bravo, Calle Esmeralda No. 102 entre Miguel Hidalgo y
        Boulevard Bahía
        <br />
        C.P. 77098. Chetumal, Quintana Roo, México. Tel.
        <a href="tel:983-832-2822">(983) 8322822</a>
      </p>
      <p>
        Correo electrónico para recepción de documentos:
        <a href="mailto:foroderechodelasmujeres@correo.congresoqroo.gob.mx"
          >foroderechodelasmujeres@correo.congresoqroo.gob.mx</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";

.footer {
  text-align: center;
  background: $color-02;
  color: white;
  height: 100px;
  p {
    margin-bottom: 0;
    font-size: 0.5rem;
    @include media-breakpoint-up(md) {
      font-size: 0.9rem;
    }
    a {
      color: $color-06;
    }
  }
}
</style>
