<template>
  <div class="container">
    <div class="container-form-file">
      <b-form-file
        v-model="fileData"
        :state="Boolean(fileData)"
        placeholder="Elija un archivo PDF o arrastrelo aquí..."
        drop-placeholder="Suelte el archivo aquí..."
        accept="application/pdf"
        @change="previewImage"
      ></b-form-file>
    </div>
    <div v-if="fileData != null" class="container-upload">
      <div class="p-2 container-logo-file">
        <a v-if="file" :href="file" target="_blank">
          <img class="logo-file" :src="getImgUrl(logo)" />
        </a>
        <img v-else class="logo-file" src="../assets/logos/archivo.svg" />
      </div>
      <div class="p-2 container-upload-status">
        <div class="upload-name">
          <span>{{ this.fileData.name }}</span>
        </div>
        <div>
          <b-progress :max="max">
            <b-progress-bar
              :value="uploadValue"
              :label="`${((uploadValue / max) * 100).toFixed()}%`"
            ></b-progress-bar>
          </b-progress>
        </div>
        <div class="upload-size">
          <span>{{ this.capacidad.toFixed(2) }} {{ this.unidad }}</span>
        </div>
      </div>
      <div class="p-2 container-upload-botton">
        <transition name="fade" mode="out-in">
          <svg
            v-if="file"
            version="1.1"
            id="done"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 480 480"
          >
            <path
              class="bg-done"
              d="M240,8C111.87,8,8,111.87,8,240s103.87,232,232,232s232-103.87,232-232S368.13,8,240,8z
              M205.824,301.824l-67.88-67.88L205.824,301.824L341.6,166.056L205.824,301.824z"
            />
            <g>
              <path
                class="contorno-done"
                d="M240,480C107.452,480,0,372.548,0,240S107.452,0,240,0s240,107.452,240,240
                C479.85,372.486,372.486,479.85,240,480z M240,16C116.288,16,16,116.288,16,240s100.288,224,224,224s224-100.288,224-224
                C463.859,116.347,363.653,16.141,240,16z"
              />
              <path
                class="logo-done"
                d="M205.824,309.824c-2.122,0-4.156-0.844-5.656-2.344l-67.88-67.88
                c-3.069-3.178-2.981-8.243,0.197-11.312c3.1-2.994,8.015-2.994,11.115,0l62.224,62.224L335.928,160.4
                c3.069-3.178,8.134-3.266,11.312-0.196c3.178,3.069,3.266,8.134,0.196,11.312c-0.064,0.067-0.13,0.132-0.196,0.196L211.48,307.48
                C209.98,308.98,207.946,309.824,205.824,309.824L205.824,309.824z"
              />
            </g>
          </svg>
          <img
            v-else
            class="botton-upload"
            @click="onUpload"
            src="../assets/logos/subir.svg"
            alt=""
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../firebase";

export default {
  name: "UploadFile",
  data() {
    return {
      fileData: null,
      file: null,
      uploadValue: 0,
      max: 100,
      capacidad: 0,
      unidad: "",
      logo: "",
    };
  },
  methods: {
    previewImage(event) {
      this.uploadValue = 0;
      this.file = "";
      this.fileData = event.target.files[0];
      this.unidad = "";
      this.logo = "";
      //console.log(this.fileData);

      if (this.fileData.type == "application/pdf") {
        this.logo = "pdf.svg";
      } else if (this.fileData.type == "application/msword") {
        this.logo = "doc.svg";
      } else {
        this.logo = "archivo.svg";
      }

      if (this.fileData.size > 1024 && this.fileData.size < 1048576) {
        this.unidad = "KB";
        this.capacidad = this.fileData.size / 1024;
        //console.log(this.unidad)
      } else if (
        this.fileData.size >= 1048576 &&
        this.fileData.size < 1073741824
      ) {
        this.unidad = "MB";
        this.capacidad = this.fileData.size / 1048576;
        //console.log(this.unidad)
      } else if (
        this.fileData.size >= 1073741824 &&
        this.fileData.size < 1099511627776
      ) {
        this.unidad = "GB";
        this.capacidad = this.fileData.size / 1073741824;
        //console.log(this.unidad)
      }
    },
    onUpload() {
      this.file = null;
      //const metadata = { contentType: 'img/*' }
      //const storageRef = firebase.storage().ref('logos/').put(this.fileData, metadata);
      const storageRef = firebase
        .storage()
        .ref("doctos/" + this.fileData.name)
        .put(this.fileData);

      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.file = url;
            this.$emit("getURL", this.file);
          });
        }
      );
    },
    getImgUrl(logo) {
      return require("../assets/logos/" + logo);
    },
  },
};
</script>
